<template>
  <div class="login">
    <div class="container">
      <base-alert v-if="accountDeleted"
                  type="info">Account has been deleted.</base-alert>

      <div class="title">
        Login to <img :alt="appName"
           :src="require('@/assets/images/logos/pulsetic.svg')" />
      </div>
      <div class="login-card">

        <p class="mb-4" v-if="showPasswordChangedMessage">
          Your password has been changed!
        </p>

        <p class="mb-4" v-if="isAppSumo && appSumoEmail">
          In order to setup an account please choose a password.
        </p>

        <auth-via-google v-if="!isAppSumo && !appSumoEmail"
                         title="Continue with Google"
                         class="mb-4"/>

        <div class="alternative-login" v-if="!isAppSumo && !appSumoEmail">
         <span>OR</span>
        </div>

        <form ref="loginForm"
              @submit.prevent="runCaptcha">
          <base-textbox v-if="isAppSumo && appSumoEmail"
                        label="Email"
                        inputmode="email"
                        :value="appSumoEmail"
                        disabled />

          <base-textbox v-else
                        label="Email"
                        ref="email"
                        inputmode="email"
                        v-model="credentials.email"
                        placeholder="Enter your email address" />

          <base-textbox label="Password"
                        type="password"
                        ref="password"
                        v-model="credentials.password"
                        :show-password-toggle="true"
                        :show-password-reset="true"
                        :placeholder="isAppSumo ? 'Enter your new password'  :  'Enter your password'" />

          <div class="tools" :class="$mq">
            <base-button type="submit"
                         class="login-button mb-2 mb-lg-0"
                         color="primary"
                         size="big"
                         :loading="loading">{{ isAppSumo ? 'Create password' : 'Login' }}</base-button>
            <router-link class="forgot-password" v-if="!isAppSumo"
                         :to="{ name: 'forgotPassword' }">Lost Your Password?</router-link>
          </div>
        </form>

        <base-alert v-if="showLoginError"
                    class="alert-login-error"
                    type="error">{{showLoginError}}</base-alert>

        <div class="d-none">
          <vue-recaptcha ref="recaptcha"
                       size="invisible"
                       :sitekey="recaptchaKey"
                       @verify="loginFormSubmit"
                       @expired="onCaptchaExpired" />
        </div>
      </div>
      <div class="login-footer">
        <span v-if="!isAppSumo"><router-link :to="{ name: 'signup' }">Create account</router-link> &nbsp; • &nbsp; </span><a href="https://pulsetic.com/terms/">Terms</a> &nbsp; • &nbsp; <a href="https://pulsetic.com/privacy/">Privacy</a>
      </div>
      <recaptcha-disclaimer />
    </div>

    <a class="back-to-home" href="https://pulsetic.com/">←  To <span>Home Page</span></a>
  </div>
</template>

<script>
import AuthViaGoogle from '@/components/Auth/AuthViaGoogle.vue'
import RecaptchaDisclaimer from '@/components/Auth/AuthRecaptchaDisclaimer.vue'
import VueRecaptcha from 'vue-recaptcha'
import auth from '@/services/auth'

export default {
  components: {
    AuthViaGoogle,
    VueRecaptcha,
    RecaptchaDisclaimer
  },

  metaInfo: {
    title: 'Login'
  },

  data () {
    return {
      appName: process.env.VUE_APP_BRAND_NAME,

      credentials: {
        email: '',
        password: '',
        recaptcha_token: null
      },

      showLoginError: false,
      loading: false,

      recaptchaKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY,

      accountDeleted: undefined,

      isAppSumo: false,
      appSumoEmail: null
    }
  },

  mounted () {
    this.accountDeleted = this.$router.currentRoute.query.account_deleted
    this.isAppSumo = this.$router.currentRoute.query.source === 'appsumo'
    this.appSumoEmail = (this.isAppSumo && this.$router.currentRoute.query.email) ?? this.$router.currentRoute.query.email
    if (this.isAppSumo && this.appSumoEmail) {
      auth.logout()
      this.$refs.password.focus()
    } else {
      this.$refs.email.focus()
    }
  },

  methods: {
    runCaptcha () {
      this.loading = true
      this.$refs.recaptcha.execute()
    },

    async loginFormSubmit (recaptcha_token) {
      try {
        let credentialsData

        if (this.isAppSumo && this.appSumoEmail) {
          credentialsData = {
            'email': this.appSumoEmail,
            'uuid': this.isAppSumo && this.$router.currentRoute.query.uuid,
            'isAppSumo': this.isAppSumo
          }
        } else {
          credentialsData = this.credentials
        }

        const response = await this.login({
          ...credentialsData,
          ...{ recaptcha_token }
        })

        if (response && response.tfa && response.tfa === true) {
          await this.$router.push({
            name: 'twoFactorAuthenticationCheck',
            params: { token: response.token, loginType: response.loginType }
          })
        } else {
          window.location.href = '/'
        }
      } catch (e) {
        if (e.response && e.response.data && e.response.data.error) {
          this.showLoginError = e.response.data.error
        } else {
          this.showLoginError = 'The email or password is not correct.'
        }
        this.$refs.recaptcha.reset()
      }

      this.loading = false
    },

    onCaptchaExpired () {
      this.$refs.recaptcha.reset()
    },

    ...mapActions('authentication', [
      'login'
    ])
  },

  computed: {
    showPasswordChangedMessage () {
      return this.$route.query.passwordReset === '1'
    }
  }
}
</script>

<style lang="scss" scoped>
  .login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    //padding: 10px 0;
    //background-image: linear-gradient(to top, #dad4ec 0%, #dad4ec 1%, #f3e7e9 100%);
    background: #f9f9f9;
    .container {
      max-width: 446px;
      padding: 0px;
    }

    @media (max-width: 500px) {
      .container {
        width: 100%;
        max-width: none;
        padding: 0 20px;
      }
    }

    .title {
      // display: flex;
      // align-items: center;
      margin-bottom: 20px;

      font-size: 21px;
      font-weight: 500;

      width: 100%;
      text-align: center;
      letter-spacing:  -0.017em;

      img {
        height: 28px;
        vertical-align: text-bottom;
      }
    }

    .login-card {
      padding: 40px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05), 0px 10px 30px rgba(0, 0, 0, 0.05);
      border: 1px solid #E4E8ED;
    }

    .login-footer {
      font-size: 14px;
      color: #828282;
      text-align: center;
      padding: 30px;

      a {
        color: #828282;

        &:hover {
          color: map-get($colors, purple-1);
        }
      }
    }

    .tools {
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      align-items: center;
      //margin-bottom: 20px;

      .login-button {
        width: 180px;
      }

      .forgot-password {
        font-size: 15px;
        margin-left: 20px;
      }

      &.mobile {
        .login-button {
          width: 150px;
        }

        .forgot-password {
          font-size: 14px;
        }
      }
    }

    .alert-login-error {
      margin-top: 20px;
    }

    .alternative-login {
      background-image: linear-gradient(90deg,#e4e8ed 33%,#e4e8ed 0);
      background-position: bottom;
      background-size: 1px 1px;
      background-repeat: repeat-x;
      text-align: center;
      height: 11px;
      margin-bottom: 30px;

      span {
        background: #fff;
        padding: 0 12px;
        color: #aaa;
        font-size: 12px;
        font-weight: 500;
      }
    }

    .back-to-home {
      color: #333;
      font-size: 13px;
      font-weight: 500;
      position: absolute;
      top: 0;
      left: 0;
      padding: 22px;
      span {
        font-weight: 700;
      }

      &:hover {
        color: map-get($colors, purple-1);
      }
    }
  }
</style>
